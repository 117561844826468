import React from "react"
import { Link } from "gatsby"

const Footer = props => {
  return (
    <footer>
        <div className="footer_column">
          <h4>Important Links</h4>
          <ul>
          <li>
              <Link  rel="nofollow"  to="/writeforus">Guest Blogging</Link>
            </li>
            <li>
              <Link  rel="nofollow"  to="/disclaimer">Disclaimer and Disclosure</Link>
            </li>
            <li>
              <Link  rel="nofollow"  to="/terms">Terms and Conditions</Link>
            </li>
            <li>
              <Link  rel="nofollow"  to="/privacy">Privacy Policy</Link>
            </li> 
            <li>
              <Link  rel="nofollow"  to="/contactus">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div  className="footer_column">
        <h4>FTC Disclosure</h4>
          <p>
          Disclosure: We are compensated for our reviews. Click <a href="/disclaimer">here</a> for details.
          </p>
        
          <h4>About Us</h4>
          <p>
          We are web developers and web masters and in the last 18+ years, we have used many web hosts, good and bad! And hence our reviews here can help folks like you find the best web host. You save time and money as well.
          </p>
        </div>
 
    </footer>
  )
}

export default Footer
